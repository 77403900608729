import $ from 'jquery';
import Swipe from '../assets/swipe/js/swipe.min.js';

global.$ = global.jQuery = $;

require('jquery-circle-progress');
require('./plugins/dzsparallaxer/dzsparallaxer.js');
require('./../vendor/codefog/contao-mobile_menu/assets/js/mobile-menu.jquery');
require('./../assets/colorbox/js/colorbox.min.js');

$(document).ready(function() {
    // Autofocus
    (() => {
        var p = document.querySelector('p.error') || document.querySelector('p.confirm');
        if (p) {
            p.scrollIntoView();
        }
    })();

    // Circle progress
    (() => {
        $('[data-progress-circle]').each(function () {
            var element = $(this);
            var offset = 0.9; // 90%

            var interval = setInterval(function () {
                if ($(window).scrollTop() + (offset * $(window).height()) < element.offset().top) {
                    return;
                }

                clearInterval(interval);

                element.circleProgress({
                    value: parseFloat(element.data('progress-circle')),
                    size: 123,
                    startAngle: Math.PI / -2,
                    fill: {
                        color: '#ffffff',
                        image: require('./images/progress-circle.png'),
                    },
                    emptyFill: '#ffffff'
                }).on('circle-animation-progress', function (event, progress, stepValue) {
                    $(this).next('.progress-value').find('.value').html(parseInt(100 * stepValue));
                });
            }, 500);
        });
    })();

    // Footer "scroll to top" link
    (() => {
        $('#footer .top_link').on('click', function(e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 });
        });
    })();

    // Colorbox
    (() => {
        $('a[data-lightbox]').map(function() {
            $(this).colorbox({
                // Put custom options here
                loop: false,
                rel: $(this).attr('data-lightbox'),
                maxWidth: '95%',
                maxHeight: '95%'
            });
        });
    })();

    // Slider
    (() => {
        var e = document.querySelectorAll('.content-slider, .slider-control'), c, i;
        for (i=0; i<e.length; i+=2) {
            c = e[i].getAttribute('data-config').split(',');
            new Swipe(e[i], {
                'auto': parseInt(c[0]),
                'speed': parseInt(c[1]),
                'startSlide': parseInt(c[2]),
                'continuous': parseInt(c[3]),
                'menu': e[i+1]
            });
        }
    })();

    // Project list
    (() => {
        var list = $('.project-list');

        $('.project-list-switcher a').on('click', function (e) {
            e.preventDefault();

            if ($(this).hasClass('list-view') && !list.hasClass('list-view')) {
                list.fadeOut(function () {
                    list.addClass('list-view').removeClass('tile-view').fadeIn();
                });
            }

            if ($(this).hasClass('tile-view') && !list.hasClass('tile-view')) {
                list.fadeOut(function () {
                    list.addClass('tile-view').removeClass('list-view').fadeIn();
                });
            }
        });
    })();

    // Project reader
    (() => {
        var header = $('#header');
        var projectHeader = $('.project-header');
        var projectTitle = $('.project-title');
        var offset = 15; // width of black frame around page
        var headerHeight = header.outerHeight();
        var projectHeaderHeight = projectHeader.outerHeight();
        var projectTitleHeight = projectTitle.outerHeight();

        function update() {
            if ($(window).scrollTop() >= projectHeaderHeight - projectTitleHeight - headerHeight - offset) {
                header.addClass('solid');
            } else {
                header.removeClass('solid');
            }
        }

        $(window).on('scroll', update);
        update();
    })();
});

$(window).on('load', function() {
    if (!document.body.classList.contains('layout-homepage')) {
        return;
    }

    var header = $('#header').css('position', 'absolute').show();
    var articles = $('#main').find('.mod_article');
    var fullview = articles.filter('.fullview');
    var firstProject = articles.filter('[class*="homepage-project"]').eq(0);
    var projectTeasers = articles.find('.ce_am_project_teaser').find('a');

    fullview.each(function () {
        $(this).data('initial-height', $(this).outerHeight());
    });

    // Get the offset
    function getOffset() {
        return {'top': 0, 'bottom': 0, 'total': 0};
    }

    // Set the header offset
    function setHeaderOffset() {
        header.css('top', firstProject.offset().top);
    }

    // Set the header position
    function setHeaderPosition() {
        if ($(window).scrollTop() >= firstProject.offset().top - getOffset().top) {
            header.css({'position': 'fixed', 'top': getOffset().top}).addClass('solid');
        } else {
            header.css({'position': 'absolute', 'top': firstProject.offset().top}).removeClass('solid');
        }
    }

    // Make the articles the height of the window
    function setArticleHeight() {
        var windowHeight = $(window).height();

        fullview.each(function () {
            var height = windowHeight;

            if (windowHeight < $(this).data('initial-height')) {
                height = $(this).data('initial-height');
            }

            $(this).css('height', height - getOffset().total);
        });
    }

    // Set the article content offset
    function setArticleContentOffset() {
        var scrollTop = $(window).scrollTop();
        var viewportHeight = $(window).height() - getOffset().total;

        fullview.each(function () {
            var article = $(this);
            var articleHeight = article.outerHeight();
            var articleOffset = article.offset().top;
            var margin = scrollTop - articleOffset + getOffset().top;

            // If the article is higher than viewport make sure it's scrollable
            if (articleHeight > viewportHeight) {
                var scrollOffset = scrollTop + viewportHeight + getOffset().bottom;

                if (scrollOffset >= (articleOffset + articleHeight)) {
                    margin = scrollOffset - (articleOffset + articleHeight);
                } else {
                    margin = 0;
                }

            }

            article.children('.inner').css('margin-top', ((margin > 0) ? margin : 0));
        });
    }

    // Set the active project teasers
    function setActiveProjectTeasers() {
        var threshold = ($(window).height() / 2) + $(window).scrollTop();
        var activeTeaser = null;

        projectTeasers.each(function () {
            var teaser = $(this);

            if (threshold > teaser.offset().top) {
                activeTeaser = teaser;
            }
        });

        projectTeasers.removeClass('active');

        if (activeTeaser) {
            activeTeaser.addClass('active');
        }
    }

    $(window).on('resize', function () {
        setArticleHeight();
        setHeaderOffset();
        setActiveProjectTeasers();
    });

    $(window).on('scroll', function () {
        setHeaderPosition();
        setArticleContentOffset();
        setActiveProjectTeasers();
    });

    setArticleHeight();
    setHeaderOffset();
    setArticleContentOffset();
    setActiveProjectTeasers();
});

// Import SCSS
import './styles/app.scss';
